<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">修改密码</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="form_box"  label-position='right' size="small">
                    <el-form-item style="margin-top:30px;">
                        <template slot-scope="scope">
                            <div style="display:flex;" :src="scope.row">
                                <p style="min-width:90px;text-align:center;"><span style="color:red">*</span>旧密码：</p>
                                <el-input :maxlength="20" type="password" v-model="oldPassword" clearable placeholder="请输入旧密码"></el-input>
                            </div>
                        </template>
					</el-form-item>
                    <el-form-item style="margin-top:30px;">
						 <template slot-scope="scope">
                            <div style="display:flex;" :src="scope.row">
                                <p style="min-width:90px;text-align:center;"><span style="color:red">*</span>新密码：</p>
                                <el-input :maxlength="20" type="password" v-model="newPassword" clearable placeholder="请输入新密码"></el-input>
                            </div>
                        </template>
					</el-form-item>
                    <el-form-item  style="margin-top:30px;">
						 <template slot-scope="scope">
                            <div style="display:flex;" :src="scope.row">
                                <p style="min-width:90px;text-align:center;"><span style="color:red">*</span>确认密码：</p>
                                <el-input :maxlength="20" type="password" v-model="newPassword2" clearable placeholder="请确认新密码名"></el-input>
                            </div>
                        </template>
					</el-form-item>
                    <el-form-item size="large" style="margin-top:30px;margin-left:120px;">
                        <el-button  type="primary" @click="changePassword()">修改</el-button>
                    </el-form-item>
                </el-form>
			</div>
		</div>
	</div>
</template>

<script>
import {
    passwordUpdate
} from "@/api/platform/index";
export default {
    name: "passwordUpdate",
    data() {
        return {
            oldPassword:'',
            newPassword:'',
            newPassword2:'',
        };
    },
    mounted() {
    },
    created() {
    },
    methods: {
        changePassword(){
            let that = this;
            if(that.oldPassword == ''){
                that.$message.warning('旧密码不能为空');
            }else if(that.newPassword == ''){
                that.$message.warning('新密码不能为空');
            }else if(that.newPassword === that.newPassword2){
                passwordUpdate({
                    oldPassword: that.oldPassword,
                    newPassword: that.newPassword,
                    newPassword2: that.newPassword2,
                }).then(res => {
                    if (res.code == 200) {
                        that.oldPassword = '';
                        that.newPassword = '';
                        that.newPassword2 = '';
                        that.$message.success(res.msg);
                    } else {
                        that.$message.error(res.msg);
                    }
                }).catch(e => {
                    that.$message({
                        message: e.msg,
                        type: 'error'
                    });
                })
            }else{
                that.$message.warning('新密码，确认密码输入不一致');
            }
            
        }
       
    }
}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
</style>
